<template>
  <div class="card questions">
    <div class="card-header">
      <h3 class="card-title">{{ $t('page.title.score_details') }}: <span
          v-if="score">{{ score.title }}</span></h3>
      <div class="float-right">
        <router-link :to="{'name':'scores-list'}">
          <b-button size="sm" variant="outline-primary">{{ $t('btn.back') }}</b-button>
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <b-card v-if="score">
            <b-card-header><b>{{ $t('page.general_information') }}</b></b-card-header>
            <b-card-body>
              <label>Id</label>
              <p>{{ score.id }}</p>


              <span v-if="isEdit">
                <label>{{ $t('page.score.file_type') }}</label>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="fileType" id="fileType1" value="fromComputer" v-model="fileType">
                  <label class="form-check-label" for="fileType1">
                    {{ $t('page.score.file_from_computer_type') }}
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="fileType" id="fileType2" value="externalFile" v-model="fileType">
                  <label class="form-check-label" for="fileType2">
                    {{ $t('page.score.external_file_type') }}
                  </label>
                  <br/><br/>
                </div>

                <span v-if="fileType == 'fromComputer'">
                  <label>{{ $t('page.score.file') }}</label>
                  <b-form-file
                               accept="application/pdf"
                               @change="uploadFile($event)"
                  ></b-form-file>

                  <a :href="filePath" target="_blank">{{ filePath }}</a>
                  <button v-if="filePath" type="button" @click="deleteFile()"  class="mr-1">
                    X
                  </button>
                  <br/>
                </span>

                <span v-if="fileType == 'externalFile'">
                  <label>{{ $t('page.score.external_file_type') }}</label>
                  <input v-model="formEdit.externalFileUrl"  class="form-control"/>
                </span>
                <br/>
              </span>

              <span v-else>
                <span v-if="score.file != null">
                  <label>{{ $t('page.score.file') }}</label>
                  <p><a :href="filePath" target="_blank">{{ filePath }}</a></p>
                </span>
                <span v-else>
                  <label>{{ $t('page.score.external_file_type') }}</label>
                  <p><a :href="score.externalFileUrl" target="_blank">{{ score.externalFileUrl }}</a></p>
                </span>
              </span>

              <label>{{ $t('page.score.title') }}</label>
              <p>
                <input v-model="formEdit.title" v-if="isEdit" class="form-control">
                <span v-else>{{ score.title }}</span>
              </p>

              <label>{{ $t('page.score.additional_title') }}</label>
              <p>
                <input v-model="formEdit.additionalTitle" v-if="isEdit" class="form-control">
                <span v-else>{{ score.additionalTitle }}</span>
              </p>

              <label>{{ $t('page.score.length') }}</label>
              <p>
                <input v-model="formEdit.length" v-if="isEdit" class="form-control" type="number">
                <span v-else>{{ score.length }}</span>
              </p>

              <label>{{ $t('page.score.year') }}</label>
              <p>
                <input v-model="formEdit.year" v-if="isEdit" class="form-control" type="number">
                <span v-else>{{ score.year }}</span>
              </p>

              <label>{{ $t('page.score.music_author') }}</label>
              <p>
                <multiselect v-if="isEdit"
                     multiple
                     v-model="selectedMusicAuthors"
                     :options="authors"
                     track-by="key"
                     label="value"
                ></multiselect>
                <span v-for="(authorId, index) in score.musicAuthors" :key="authorId" v-else>
                      {{ getAuthorById(authorId) }}<span v-if="index != score.musicAuthors.length - 1">, </span>
                </span>
              </p>

              <label>{{ $t('page.score.lyrics_author') }}</label>
              <p>
                <multiselect v-if="isEdit"
                             multiple
                             v-model="selectedLyricsAuthors"
                             :options="authors"
                             track-by="key"
                             label="value"
                ></multiselect>
                <span v-for="(authorId, index) in score.lyricsAuthors" :key="authorId" v-else>
                      {{ getAuthorById(authorId) }}<span v-if="index != score.lyricsAuthors.length - 1">, </span>
                </span>
              </p>

              <label>{{ $t('page.score.arrangement_author') }}</label>
              <p>
                <multiselect v-if="isEdit"
                             multiple
                             v-model="selectedArrangementAuthors"
                             :options="authors"
                             track-by="key"
                             label="value"
                ></multiselect>
                <span v-for="(authorId, index) in score.arrangementAuthors" :key="authorId" v-else>
                   {{ getAuthorById(authorId) }}<span v-if="index != score.arrangementAuthors.length - 1">, </span>
                </span>
              </p>

              <label>{{ $t('page.score.cast_details') }}</label>
              <p>
                <textarea v-model="formEdit.castDetails" v-if="isEdit" class="form-control"></textarea>
                <span v-else>{{ score.castDetails }}</span>
              </p>

              <label>{{ $t('page.score.genre') }}</label>
              <p>
                <multiselect v-if="isEdit"
                             multiple
                             v-model="selectedGenres"
                             :options="genres"
                             track-by="key"
                             label="value"
                >

                </multiselect>
                <span v-for="(genre, index) in score.genres" :key="genre" v-else>
                   {{ $t('dictionary.genres.' + genre) }}<span v-if="index != score.genres.length - 1">, </span>
                </span>
              </p>

              <label>{{ $t('page.score.score_type') }}</label>
              <p>
                <multiselect v-if="isEdit"
                             multiple
                             v-model="selectedScoreTypes"
                             :options="scoreTypes"
                             track-by="key"
                             label="value"
                >
                </multiselect>
                <span v-for="(scoreType, index) in score.scoreTypes" :key="scoreType" v-else>
                   {{ $t('dictionary.score_types.' + scoreType) }}<span v-if="index != score.scoreTypes.length - 1">, </span>
                </span>
              </p>

              <label>{{ $t('page.score.line_up') }}</label>
              <p>
                <multiselect v-if="isEdit"
                             multiple
                             v-model="selectedLineUp"
                             :options="lineUp"
                             track-by="key"
                             label="value"
                >
                </multiselect>
                <span v-for="(item, index) in score.lineUp" :key="item" v-else>
                   {{ $t('dictionary.line_up.' + item) }}<span v-if="index != score.lineUp.length - 1">, </span>
                </span>
              </p>

              <label>{{ $t('page.score.price') }}</label>
              <p>
                <input v-model="formEdit.price" v-if="isEdit" class="form-control" type="number" step="0.01">
                <span v-else>{{ score.price }} PLN</span>
              </p>

              <label>{{ $t('page.score.url') }}</label>
              <p>
                <input v-model="formEdit.url" v-if="isEdit" class="form-control" >
                <span v-else>{{ score.url }}</span>
              </p>
              <label>{{ $t('page.score.includeMultimediaOrElectronic') }}</label>
              <div class="form-check" v-if="isEdit">
                <input class="form-check-input" type="checkbox" v-model="formEdit.includeMultimediaOrElectronic">
              </div>
              <p v-else>{{ score.includeMultimediaOrElectronic ? 'tak' : 'nie'}}</p>

            </b-card-body>

          </b-card>
        </div>
        <div class="col-6" v-if="score">
          <b-card>
            <b-card-header><b>{{ $t('page.score.assigned_lessons') }}</b></b-card-header>

            <br/>
            <label>{{ $t('page.score.lessons') }}</label>
            <p>
              <multiselect
                  v-if="isEdit"
                  multiple
                  v-model="selectedLessons"
                  :options="lessons"
                  track-by="key"
                  label="value"
              >
              </multiselect>
              <ul v-else>
              <li v-for="lessonId in score.lessonIds" :key="lessonId">
                {{ getLessonById(lessonId) }}
              </li>
              </ul>
            </p>

          </b-card>
        </div>

      </div>
      <div class="buttons float-right">
        <b-button v-if="!isEdit" type="button" @click="startEdit()" variant="success" class="mr-1">{{ $t('actions.edit') }}</b-button>
        <b-button v-if="isEdit" type="button" @click="changeData()" variant="success" class="mr-1">{{ $t('actions.save') }}</b-button>
        <b-button v-if="isEdit" type="button" @click="isEdit = false" variant="danger" class="mr-1">{{ $t('actions.cancel') }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>

import alertify from 'alertifyjs';
import axios from "axios";
import Multiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.min.css";
import {mapState} from "vuex";

export default {
  props: ['apiKey'],
  components: {
    Multiselect,
  },
  data() {
    return {
      fileType: null,
      scoreId: null,
      formEdit: {},
      isEdit: false,
      selectedMusicAuthors: [],
      selectedLyricsAuthors: [],
      selectedArrangementAuthors: [],
      selectedGenres: [],
      selectedScoreTypes: [],
      selectedLineUp: [],
      selectedLessons: []
    };
  },
  mounted() {
    this.getScore();
    this.getGenres();
    this.getAuthors();
    this.getScoreTypes();
    this.getLineUp();
    this.getLessons()
  },
  computed: {
    score: function () {
      return this.$store.getters["scoresStore/score"];
    },
    filePath: function () {
      return this.$store.getters["scoresStore/filePath"];
    },
    ...mapState({
      genres: (state) => state.scoresStore.genres,
      authors: (state) => state.scoresStore.authors,
      scoreTypes: (state) => state.scoresStore.scoreTypes,
      lineUp: (state) => state.scoresStore.lineUp,
      lessons: (state) => state.scoresStore.lessons,
    }),
  },
  beforeDestroy() {
    this.$store.commit('scoresStore/setScore', null);
  },
  created() {
    this.scoreId = this.$route.params.id;
  },
  methods: {
    getGenres() {
      this.$store.dispatch('scoresStore/getGenres');
    },
    getAuthors() {
      this.$store.dispatch('scoresStore/getAuthors');
    },
    getLessons() {
      this.$store.dispatch('scoresStore/getLessons', this.scoreId);
    },
    getLessonById(id) {
      let obj = this.lessons.find(lesson => lesson.key === id);

      return obj ? obj.value : null;
    },
    getScoreTypes() {
      this.$store.dispatch('scoresStore/getScoreTypes');
    },
    getLineUp() {
      this.$store.dispatch('scoresStore/getLineUp');
    },
    getAuthorById(id) {
      let obj = this.authors.find(author => author.key === id);

      return obj ? obj.value : null;
    },
    uploadFile(event) {
      let formData = new FormData();

      formData.append("file", event.target.files[0]);

      axios.post("/file/pdf/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
      })
          .then((response) => {
            this.formEdit.file = response.data.file;
            this.$store.dispatch('scoresStore/changeFilePath', response.data.path)
      })
      .catch(error => {
        console.log(error);
      });
    },
    deleteFile() {
      this.formEdit.file = null;
      this.$store.dispatch('scoresStore/changeFilePath', null);
    },
    startEdit() {
      this.fileType = this.score.file !== null ? 'fromComputer' : 'externalFile'
      this.formEdit = JSON.parse(JSON.stringify(this.score));
      this.isEdit = true;
      this.selectedMusicAuthors = this.authors.filter((el) => this.score.musicAuthors.includes(el.key));
      this.selectedLyricsAuthors = this.authors.filter((el) => this.score.lyricsAuthors.includes(el.key));
      this.selectedArrangementAuthors = this.authors.filter((el) => this.score.arrangementAuthors.includes(el.key));
      this.selectedGenres = this.genres.filter((el) => this.score.genres.includes(el.key));
      this.selectedScoreTypes = this.scoreTypes.filter((el) => this.score.scoreTypes.includes(el.key));
      this.selectedLineUp = this.lineUp.filter((el) => this.score.lineUp.includes(el.key));
      this.selectedLessons = this.lessons.filter((el) => this.score.lessonIds.includes(el.key));
    },
    getScore() {
      this.$store.dispatch('scoresStore/getScore', this.scoreId);
    },
    changeData() {
      if (this.fileType == 'fromComputer') {
        this.formEdit.externalFileUrl = null;
      } else {
        this.formEdit.file = null;
      }

      const url = '/score/' + this.scoreId +'/edit';
      this.formEdit.lessonIds = this.selectedLessons.map((el) => el.key);
      this.formEdit.musicAuthors = this.selectedMusicAuthors.map((el) => el.key);
      this.formEdit.lyricsAuthors = this.selectedLyricsAuthors.map((el) => el.key);
      this.formEdit.arrangementAuthors = this.selectedArrangementAuthors.map((el) => el.key);
      this.formEdit.genres = this.selectedGenres.map((el) => el.key);
      this.formEdit.scoreTypes = this.selectedScoreTypes.map((el) => el.key);
      this.formEdit.lineUp = this.selectedLineUp.map((el) => el.key);
      this.formEdit.price = parseFloat(this.formEdit.price);
      this.formEdit.length = parseInt(this.formEdit.length);
      this.formEdit.year = this.formEdit.year ? parseInt(this.formEdit.year) : null;

      axios.post(url, this.formEdit)
          .then(() => {
            alertify.success('Utwór został zmieniony');
            this.getScore();
            this.isEdit = false;
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
    },
  },
  watch: {
    $route() {
    }
  }
}
</script>

