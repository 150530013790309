<template>
  <div class="card questions">
    <div class="card-header">
      <h3 class="card-title">{{ $t('page.title.author_details') }}: <span
          v-if="author">{{ author.firstName }} {{ author.lastName }}</span></h3>
      <div class="float-right">
        <router-link :to="{'name':'authors-list'}">
          <b-button size="sm" variant="outline-primary">{{ $t('btn.back') }}</b-button>
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <b-card v-if="author">
            <b-card-header><b>{{ $t('page.general_information') }}</b></b-card-header>
            <b-card-body>
              <label>Id</label>
              <p>{{ author.id }}</p>
              <label>{{ $t('page.first_name') }}</label>
              <p>
                <input class="form-control" v-model="formAuthorData.firstName" v-if="editAuthorData">
                <span v-else>{{ author.firstName }}</span>
              </p>
              <label>{{ $t('page.last_name') }}</label>
              <p>
                <input class="form-control" v-model="formAuthorData.lastName" v-if="editAuthorData">
                <span v-else>{{ author.lastName }}</span>
              </p>
              <label>{{ $t('page.status') }}</label>
              <p>
                <vue-select
                    v-if="editAuthorData"
                    v-model="formAuthorData.status"
                    :options="statuses" :reduce="status => status.value"
                    label="text"
                ></vue-select>
                <span v-else>{{ author.status }}</span>
              </p>
              <label>{{ $t('page.description') }}</label>
              <p>
                <textarea class="form-control" v-model="formAuthorData.description" rows="8" cols="38" v-if="editAuthorData"></textarea>
                <span v-else>{{ author.description }}</span>
              </p>
              <label>{{ $t('page.welcoming_movie') }}</label>
              <p>
                <input class="form-control" v-model="formAuthorData.welcomingMovieId" v-if="editAuthorData">
                <span v-else>{{ author.welcomingMovieId }}</span>
              </p>
              <label>{{ $t('page.avatar') }}</label>
              <p>
                <b-form-file v-if="editAuthorData"
                    ref="file-input"
                    accept="image/*"
                    @change="uploadFile($event)"
                ></b-form-file>
                <span v-if="avatarPath">
                  <img :src="avatarPath" class="img-thumbnail"/>
                  <b-button v-if="editAuthorData" type="button" @click="deleteAvatar" variant="danger" class="mr-1">{{ $t('actions.delete') }}</b-button>
                </span>
              </p>
            </b-card-body>
            <div class="buttons float-right">
              <b-button v-if="!editAuthorData" type="button" @click="startEditAuthor()" variant="success" class="mr-1">{{ $t('actions.edit') }}</b-button>
              <b-button v-if="editAuthorData" type="button" @click="changeAuthorData()" variant="success" class="mr-1">{{ $t('actions.save') }}</b-button>
              <b-button v-if="editAuthorData" type="button" @click="editAuthorData = false" variant="danger" class="mr-1">{{ $t('actions.cancel') }}</b-button>
            </div>
          </b-card>
        </div>
        <div class="col-6">

        </div>

      </div>
    </div>
  </div>
</template>

<script>

import alertify from 'alertifyjs';
import axios from "axios";
import { VueSelect } from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  props: ['apiKey'],
  components: {
    VueSelect
  },
  data() {
    return {
      statuses: [
        {value: 'active', text: 'Active'},
        {value: 'inactive', text: 'Inactive'},
        {value: 'bonus', text: 'Bonus'},
        {value: 'deleted', text: 'Deleted'},
      ],
      authorId: null,
      formAuthorData: {},
      editAuthorData: false,
    };
  },
  mounted() {
    this.getAuthor();
  },
  computed: {
    author: function () {
      return this.$store.getters["authorsStore/author"];
    },
    avatarPath: function () {
      return this.$store.getters["authorsStore/avatarPath"];
    },
  },
  beforeDestroy() {
    this.$store.commit('authorsStore/setAuthor', null);
  },
  created() {
    this.authorId = this.$route.params.id;
  },
  methods: {
    uploadFile(event) {
      let formData = new FormData();

      formData.append("file", event.target.files[0]);

      axios.post("/file/image/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
      })
          .then((response) => {
            this.formAuthorData.avatar = response.data.file;
            this.$store.dispatch('authorsStore/changeAvatarPath', response.data.path)
      })
      .catch(error => {
        console.log(error);
      });
    },
    deleteAvatar() {
      this.formAuthorData.avatar = null;
      this.$refs['file-input'].reset();
      this.$store.dispatch('authorsStore/changeAvatarPath', null);
    },
    startEditAuthor() {
      this.formAuthorData = JSON.parse(JSON.stringify(this.author));
      this.editAuthorData = true;
    },
    getAuthor() {
      this.$store.dispatch('authorsStore/getAuthor', this.authorId);
    },
    changeAuthorData() {
      const url = '/author/' + this.authorId +'/edit';
      axios.post(url, this.formAuthorData)
          .then(() => {
            alertify.success('notification.success');
            this.getAuthor();
            this.editAuthorData = false;
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
    },
  },
  watch: {
    $route() {
    }
  }
}
</script>

