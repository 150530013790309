<template>
  <div class="card questions">
    <div class="card-header">
      <h3 class="card-title">{{ $t('page.title.new_score') }}</h3>
      <div class="float-right">
        <router-link :to="{'name':'scores-list'}">
          <b-button size="sm" variant="outline-primary">{{ $t('btn.back') }}</b-button>
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <b-card>
            <b-card-header><b>{{ $t('page.general_information') }}</b></b-card-header>
            <b-card-body>
              <label>{{ $t('page.score.file_type') }}</label>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="fileType" id="fileType1" value="fromComputer" v-model="fileType">
                <label class="form-check-label" for="fileType1">
                  {{ $t('page.score.file_from_computer_type') }}
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="fileType" id="fileType2" value="externalFile" v-model="fileType">
                <label class="form-check-label" for="fileType2">
                  {{ $t('page.score.external_file_type') }}
                </label>
              </div>
              <br/>
              <span v-if="fileType == 'fromComputer'">
              <label>{{ $t('page.score.file') }}</label>
                <p>
                  <b-form-file
                               accept="application/pdf"
                               @change="uploadFile($event)"
                  ></b-form-file>
                  <span v-if="filePath">
                    <a :href="filePath" target="_blank">{{ filePath }}</a>
                    <button type="button" @click="deleteFile()"  class="mr-1">
                      X
                    </button>

                  </span>
                </p>
              </span>
              <span v-if="fileType == 'externalFile'">
              <label>{{ $t('page.score.external_file_type') }}</label>
              <p>
                <input v-model="formAdd.externalFileUrl"  class="form-control">
              </p>
              </span>



              <p v-if="uploadError" style="color:red">Błąd podczas ładowania pliku. Dozwolony format to pdf</p>
              <label>{{ $t('page.score.title') }}</label>
              <p>
                <input v-model="formAdd.title"  class="form-control">
              </p>

              <label>{{ $t('page.score.additional_title') }}</label>
              <p>
                <input v-model="formAdd.additionalTitle"  class="form-control">
              </p>

              <label>{{ $t('page.score.length') }}</label>
              <p>
                <input v-model="formAdd.length"  class="form-control" type="number">
              </p>

              <label>{{ $t('page.score.year') }}</label>
              <p>
                <input v-model="formAdd.year" max="2050" class="form-control" type="number" @input="checkYear">
              </p>

              <label>{{ $t('page.score.music_author') }}</label>
              <p>
                <multiselect
                     multiple
                     v-model="selectedMusicAuthors"
                     :options="authors"
                     track-by="key"
                     label="value"
                ></multiselect>
              </p>

              <label>{{ $t('page.score.lyrics_author') }}</label>
              <p>
                <multiselect
                             multiple
                             v-model="selectedLyricsAuthors"
                             :options="authors"
                             track-by="key"
                             label="value"
                ></multiselect>
              </p>

              <label>{{ $t('page.score.arrangement_author') }}</label>
              <p>
                <multiselect
                             multiple
                             v-model="selectedArrangementAuthors"
                             :options="authors"
                             track-by="key"
                             label="value"
                ></multiselect>
              </p>

              <label>{{ $t('page.score.cast_details') }}</label>
              <p>
                <textarea v-model="formAdd.castDetails"  class="form-control"></textarea>
              </p>

              <label>{{ $t('page.score.genre') }}</label>
              <p>
                <multiselect
                             multiple
                             v-model="selectedGenres"
                             :options="genres"
                             track-by="key"
                             label="value"
                >

                </multiselect>
              </p>

              <label>{{ $t('page.score.score_type') }}</label>
              <p>
                <multiselect
                             multiple
                             v-model="selectedScoreTypes"
                             :options="scoreTypes"
                             track-by="key"
                             label="value"
                >
                </multiselect>
              </p>

              <label>{{ $t('page.score.line_up') }}</label>
              <p>
                <multiselect
                             multiple
                             v-model="selectedLineUp"
                             :options="lineUp"
                             track-by="key"
                             label="value"
                >
                </multiselect>
              </p>

              <label>{{ $t('page.score.price') }}</label>
              <p>
                <input v-model="formAdd.price"  class="form-control" type="number" step="0.01">
              </p>

              <label>{{ $t('page.score.url') }}</label>
              <p>
                <input v-model="formAdd.url"  class="form-control" >
              </p>

              <label>{{ $t('page.score.includeMultimediaOrElectronic') }}</label>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="formAdd.includeMultimediaOrElectronic">
              </div>

            </b-card-body>
          </b-card>
        </div>

        <div class="col-6">
          <b-card>
            <b-card-header><b>{{ $t('page.score.assigned_lessons') }}</b></b-card-header>

            <br/>
            <label>{{ $t('page.score.lessons') }}</label>
            <p>
              <multiselect
                  multiple
                  v-model="selectedLessons"
                  :options="lessons"
                  track-by="key"
                  label="value"
              >
              </multiselect>
            </p>

          </b-card>
        </div>

      </div>
      <div class="buttons float-right">
        <b-button type="button" @click="saveData()" variant="success" class="mr-1">{{ $t('actions.save') }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>

import alertify from 'alertifyjs';
import axios from "axios";
import Multiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.min.css";
import {mapState} from "vuex";

export default {
  props: ['apiKey'],
  components: {
    Multiselect,
  },
  data() {
    return {
      fileType: 'fromComputer',
      formAdd: {
        title: null
      },
      selectedMusicAuthors: [],
      selectedLyricsAuthors: [],
      selectedArrangementAuthors: [],
      selectedGenres: [],
      selectedScoreTypes: [],
      selectedLineUp: [],
      selectedLessons: [],
      uploadError: false
    };
  },
  mounted() {
    this.deleteFile();
    this.getGenres();
    this.getAuthors();
    this.getLessons();
    this.getScoreTypes();
    this.getLineUp();
  },
  computed: {
    filePath: function () {
      return this.$store.getters["scoresStore/filePath"];
    },
    ...mapState({
      genres: (state) => state.scoresStore.genres,
      authors: (state) => state.scoresStore.authors,
      lessons: (state) => state.scoresStore.lessons,
      scoreTypes: (state) => state.scoresStore.scoreTypes,
      lineUp: (state) => state.scoresStore.lineUp,
    }),
  },
  methods: {
    getGenres() {
      this.$store.dispatch('scoresStore/getGenres');
    },
    getAuthors() {
      this.$store.dispatch('scoresStore/getAuthors');
    },
    getLessons() {
      this.$store.dispatch('scoresStore/getLessons');
    },
    getScoreTypes() {
      this.$store.dispatch('scoresStore/getScoreTypes');
    },
    getLineUp() {
      this.$store.dispatch('scoresStore/getLineUp');
    },
    uploadFile(event) {
      let formData = new FormData();

      formData.append("file", event.target.files[0]);

      axios.post("/file/pdf/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
      })
          .then((response) => {
            this.uploadError = false;
            this.formAdd.file = response.data.file;
            this.$store.dispatch('scoresStore/changeFilePath', response.data.path)
      })
      .catch(error => {
        this.uploadError = true;
        console.log(error);
      });
    },
    deleteFile() {
      this.uploadError = false;
      this.formAdd.file = null;
      this.$store.dispatch('scoresStore/changeFilePath', null);
    },
    checkYear() {
      this.formAdd.year = this.formAdd.year.slice(0,4);
    },
    saveData() {
      if (this.fileType == 'fromComputer') {
        this.formAdd.externalFileUrl = null;
      } else {
        this.formAdd.file = null;
      }

      const url = '/score';
      this.formAdd.musicAuthors = this.selectedMusicAuthors.map((el) => el.key);
      this.formAdd.lyricsAuthors = this.selectedLyricsAuthors.map((el) => el.key);
      this.formAdd.lessonIds = this.selectedLessons.map((el) => el.key);
      this.formAdd.arrangementAuthors = this.selectedArrangementAuthors.map((el) => el.key);
      this.formAdd.genres = this.selectedGenres.map((el) => el.key);
      this.formAdd.scoreTypes = this.selectedScoreTypes.map((el) => el.key);
      this.formAdd.lineUp = this.selectedLineUp.map((el) => el.key);
      this.formAdd.price = parseFloat(this.formAdd.price);
      this.formAdd.length = parseInt(this.formAdd.length);
      this.formAdd.year = this.formAdd.year ? parseInt(this.formAdd.year) : null;
      this.formAdd.includeMultimediaOrElectronic = this.formAdd.includeMultimediaOrElectronic ?? false;

      axios.post(url, this.formAdd)
          .then(() => {
            alertify.success('Utwór został dodany');
            this.$router.push('scores');
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
    },
  },
  watch: {
    $route() {
    },
  }
}
</script>

