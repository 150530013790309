<template>
  <div class="card questions">
    <div class="card-header">
      <h3 class="card-title">{{ $t('page.title.client_details') }} <span
          v-if="client">{{ client.id }} ({{ client.email }})</span></h3>
      <div class="float-right">
        <router-link :to="{'name':'clients-list'}">
          <b-button size="sm" variant="outline-primary">{{ $t('btn.back') }}</b-button>
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <router-link :to="{'name':'client-history', params: { id: clientId }}">
        <b-button size="sm" variant="success">{{ $t('btn.client.history') }}</b-button>
      </router-link> |
      <router-link :to="{'name':'client-favorite', params: { id: clientId }}">
        <b-button size="sm" variant="success">{{ $t('btn.client.favorite') }}</b-button>
      </router-link> |
      <router-link :to="{'name':'client-rental', params: { id: clientId }}">
        <b-button size="sm" variant="success">{{ $t('btn.client.rental') }}</b-button>
      </router-link> |
      <router-link :to="{'name':'client-rates', params: { id: clientId }}">
        <b-button size="sm" variant="success">{{ $t('btn.client.rates') }}</b-button>
      </router-link> |
      <router-link :to="{'name':'client-payments', params: { id: clientId }}">
        <b-button size="sm" variant="success">{{ $t('btn.client.payment') }}</b-button>
      </router-link>
      <hr/>

      <div class="row">
        <div class="col-6">
          <b-card v-if="client">
            <b-card-header><b>{{ $t('page.general_information') }}</b></b-card-header>
            <b-card-body>
              <label>Avatar</label>
              <p>
              <img v-if="client.avatarUrl" :src="client.avatarUrl" class="img-thumbnail"/>
              </p>
              <label>Id</label>
              <p>{{ client.id }}</p>
              <label>Email</label>
              <p>
                <input v-model="formClientData.email" v-if="editClientData"  class="form-control">
                <span v-else>{{ client.email }}</span>
              </p>
              <label>{{ $t('page.blocked') }}</label>
              <p>{{ client.blocked ? $t('page.yes') : $t('page.no') }}</p>
              <label>{{ $t('page.block_reason') }}</label>
              <p>{{ client.blockReason }}</p>

              <label>{{ $t('page.client.registeredAt') }}</label>
              <p>{{ client.registeredAt }}</p>

              <label>{{ $t('page.client.lastLogin') }}</label>
              <p>{{ client.lastLogin }}</p>

              <label>{{ $t('page.client.status') }}</label>
              <p>{{ client.status }}</p>

              <label>{{ $t('page.client.agreement1') }}</label>
              <p>{{ client.agreement1 ? 'tak' : 'nie' }}</p>

              <label>{{ $t('page.client.agreement2') }}</label>
              <p>{{ client.agreement2 ? 'tak' : 'nie' }}</p>

              <label>{{ $t('page.client.agreement3') }}</label>
              <p>{{ client.agreement3 ? 'tak' : 'nie' }}</p>

            </b-card-body>
            <div class="buttons float-right">
              <b-button v-if="!editClientData" type="button" @click="startEditClientData()" variant="success" class="mr-1">{{ $t('actions.edit') }}</b-button>
              <b-button v-if="editClientData" type="button" @click="changeClientData()" variant="success" class="mr-1">{{ $t('actions.save') }}</b-button>
              <b-button v-if="editClientData" type="button" @click="editClientData = false" variant="danger" class="mr-1">{{ $t('actions.cancel') }}</b-button>
            </div>
          </b-card>

          <div class="buttons float-right">
            <b-button type="button" @click="requestChangePassword()" variant="success" class="mr-1">{{ $t('actions.request_change_password') }}</b-button>
            <b-button v-if="client?.blocked" @click="unblockClient()" type="button" variant="danger" class="mr-1">{{ $t('actions.unblock') }}</b-button>
            <b-button v-else type="button" @click="blockClient()" variant="danger" class="mr-1">{{ $t('actions.block') }}</b-button>
          </div>
        </div>

        <div class="col-6">
          <b-card>
            <b-card-header><b>{{ $t('page.invoice_data') }}</b></b-card-header>
            <b-card-body>
              <label>{{ $t('page.type') }}</label>
              <p>
                <select  class="form-control" v-if="editInvoiceData" v-model="formInvoiceData.type">
                  <option v-for="invoiceDataType in invoiceDataTypes" v-bind:key="invoiceDataType" :value="invoiceDataType">
                    {{ $t('enum.invoice_data_type.' + invoiceDataType) }}
                  </option>
                </select>
                <span v-else>{{ invoiceData?.type ? $t('enum.invoice_data_type.' + invoiceData.type) : ''  }}</span>
              </p>
              <label>{{ $t('page.company_name') }}</label>
              <p>
                <input  class="form-control" v-model="formInvoiceData.companyName" v-if="editInvoiceData">
                <span v-else>{{ invoiceData?.companyName }}</span>
              </p>
              <label>{{ $t('page.first_name') }}</label>
              <p>
                <input class="form-control" v-model="formInvoiceData.firstName" v-if="editInvoiceData">
                <span v-else>{{ invoiceData?.firstName }}</span>
              </p>
              <label>{{ $t('page.last_name') }}</label>
              <p>
                <input class="form-control" v-model="formInvoiceData.lastName" v-if="editInvoiceData">
                <span v-else>{{ invoiceData?.lastName }}</span>
              </p>
              <label>{{ $t('page.phone') }}</label>
              <p>
                <input class="form-control" v-model="formInvoiceData.phone" v-if="editInvoiceData">
                <span v-else>{{ invoiceData?.phone }}</span>
              </p>
              <label>{{ $t('page.tin') }}</label>
              <p>
                <input class="form-control" v-model="formInvoiceData.tin" v-if="editInvoiceData">
                <span v-else>{{ invoiceData?.tin }}</span>
              </p>
              <label>{{ $t('page.company_type') }}</label>
              <p>
                <select class="form-control" v-if="editInvoiceData" v-model="formInvoiceData.companyType">
                  <option v-for="companyType in companyTypes" v-bind:key="companyType" :value="companyType">
                    {{ $t('enum.company_type.' + companyType) }}
                  </option>
                </select>
                <span v-else>{{ invoiceData?.companyType ? $t('enum.company_type.' + invoiceData.companyType) : ''  }}</span>
              </p>

              <label>{{ $t('page.address') }}</label>
              <p>
                <input class="form-control" v-model="formInvoiceData.address" v-if="editInvoiceData">
                <span v-else>{{ invoiceData?.address }}</span>
              </p>
            </b-card-body>
            <div class="buttons float-right">
              <b-button v-if="!editInvoiceData" type="button" @click="startEditInvoiceData()" variant="success" class="mr-1">{{ $t('actions.edit') }}</b-button>
              <b-button v-if="editInvoiceData" type="button" @click="changeInvoiceData()" variant="success" class="mr-1">{{ $t('actions.save') }}</b-button>
              <b-button v-if="editInvoiceData" type="button" @click="editInvoiceData = false" variant="danger" class="mr-1">{{ $t('actions.cancel') }}</b-button>
            </div>
          </b-card>
        </div>

      </div>
      <div class="row">

        <div class="col-6">
          <b-card>
            <b-card-header><b>{{ $t('page.subscription.title') }}</b></b-card-header>
            <b-card-body>
              <div v-if="subscription != null">
                <label>{{ $t('page.subscription.type') }}</label>
                <p>{{ subscription.name }}</p>
                <label>{{ $t('page.subscription.from') }}</label>
                <p>{{ subscription.dateFrom }}</p>
                <label>{{ $t('page.subscription.to') }}</label>
                <p>{{ subscription.dateTo }}</p>
              </div>
              <div v-else>brak</div>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import alertify from 'alertifyjs';
import axios from "axios";


export default {
  props: ['apiKey'],
  components: {},
  data() {
    return {
      clientId: null,
      formClientData: null,
      formInvoiceData: {
        companyName : null,
        firstName: null,
        lastName: null,
        phone: null,
        tin: null,
        type: null,
        companyType: null,
        address: null,
      },
      editInvoiceData: false,
      editClientData: false,
    };
  },
  mounted() {
    this.getClient();
    this.getInvoiceData();
    this.getSubscriptionDetails()
    this.getCompanyTypes();
    this.getInvoiceDataTypes();
  },
  computed: {
    client: function () {
      return this.$store.getters["clientsStore/client"];
    },
    invoiceData: function () {
      return this.$store.getters["clientsStore/invoiceData"];
    },
    subscription: function () {
      return this.$store.getters["clientsStore/subscription"];
    },
    companyTypes: function () {
      return this.$store.getters["clientsStore/companyTypes"];
    },
    invoiceDataTypes: function () {
      return this.$store.getters["clientsStore/invoiceDataTypes"];
    },
  },
  beforeDestroy() {
    this.$store.commit('clientsStore/setClient', null);
  },
  created() {
    this.clientId = this.$route.params.id;
  },
  methods: {
    startEditClientData() {
      this.formClientData = { email: this.client.email};
      this.editClientData = true;
    },
    startEditInvoiceData() {
      if (Object.keys(this.invoiceData).length > 0) {
        this.formInvoiceData = JSON.parse(JSON.stringify(this.invoiceData));
      }
      this.editInvoiceData = true
    },
    getClient() {
      this.$store.dispatch('clientsStore/getClient', this.clientId);
    },
    getInvoiceData() {
      this.$store.dispatch('clientsStore/getClientInvoiceData', this.clientId);
    },
    getSubscriptionDetails() {
      this.$store.dispatch('clientsStore/getSubscriptionDetails', this.clientId);
    },
    getCompanyTypes() {
      this.$store.dispatch('clientsStore/getCompanyTypes');
    },
    getInvoiceDataTypes() {
      this.$store.dispatch('clientsStore/getInvoiceDataTypes');
    },
    requestChangePassword() {
      const url = '/client/' + this.clientId +'/change-password';
      axios.post(url)
          .then(() => {
            alertify.success('Link do zmiany hasła został wysłany');
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
    },
    changeClientData() {
      const url = '/client/' + this.clientId +'/change-email';
      axios.post(url, this.formClientData)
          .then(() => {
            alertify.success('notify.success');
            this.getClient();
            this.editClientData = false;
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
    },
    changeInvoiceData() {
      const url = '/client/' + this.clientId +'/change-invoice-data';
      axios.post(url, this.formInvoiceData)
          .then(() => {
            alertify.success('notify.success');
            this.getInvoiceData();
            this.editInvoiceData = false;
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
    },
    blockClient() {
      const url = '/client/' + this.clientId +'/block';
      axios.post(url)
          .then(() => {
            alertify.success('Użytkownik został zablokowany');
            this.getClient();
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
  },
    unblockClient() {
      const url = '/client/' + this.clientId +'/unblock';
      axios.post(url)
          .then(() => {
            alertify.success('Użytkownik został odblokowany');
            this.getClient();
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
    }
  },
  watch: {
    $route() {
    }
  }
}
</script>
