<template>
  <div class="w-100 p-2 mt-2">
    <page-header>
      <i class="fas fa-list"></i>
      <h3 class="card-title">{{ $t('page.title.statistics') }} - {{ $t('page.title.statistics_' + this.type ) }}</h3>
    </page-header>

    <page-card>
      <template v-slot:header>
        <nav class="nav nav-pills flex-column flex-sm-row">
          <a @click="getLessonWatched" href="#" :class="'flex-sm-fill text-sm-center nav-link' + (type === 'lessonWatched' ? ' active' : '')">{{ $t('page.title.statistics_lessonWatched')}}</a>
          <a @click="getRates" href="#" :class="'flex-sm-fill text-sm-center nav-link' + (type === 'rate' ? ' active' : '')">{{ $t('page.title.statistics_rate')}}</a>
          <a @click="getRentals" href="#" :class="'flex-sm-fill text-sm-center nav-link' + (type === 'rental' ? ' active' : '')">{{ $t('page.title.statistics_rental')}}</a>
        </nav>
      </template>
      <template v-slot:content>
        <div class="sygni-grid">
          <div class="row mt-2">
            <div class="col-12">
              <grid-pagination :total-count="totalCount"
                               :per-page="perPage"
                               :current-page="currentPage"
                               @load-items="paginationReload"></grid-pagination>

              <div class="table-responsive">
                <b-table striped show-empty
                         :empty-text="$t('table.no_results')"
                         :busy="isBusy"
                         hover
                         head-variant="dark"
                         outlined
                         :sort-by.sync="orderColumn"
                         :sort-desc.sync="orderDirection"
                         :no-local-sorting="true"
                         :items="items"
                         :fields="fields">

                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ $t('state.loading') }}</strong>
                    </div>
                  </template>

                </b-table>
              </div> <!-- #table-responsive-->

              <grid-pagination :total-count="totalCount"
                               :per-page="perPage"
                               :current-page="currentPage"
                               @load-items="paginationReload"></grid-pagination>

            </div>
          </div>
        </div>
      </template>
    </page-card>
  </div>
</template>

<script>

import GridPagination from "../../../components/grid/grid-pagination/GridPagination";
import PageHeader from "../../../components/page/PageHeader";
import PageCard from "../../../components/page/PageCard";
import {mapState} from "vuex";

export default {
  props: ['apiKey'],
  components: {
    PageCard,
    PageHeader,
    GridPagination,
  },
  data() {
    return {
      loadItemMethod: 'getLessonWatched',
      type: 'lessonWatched',
      orderColumn: null,
      clientId: null,
      orderDirection: false,
      fields: [
      ],
      selectOptions: {
        bulkActions: [
          {value: null, text: 'Bulk actions'}
        ],
      },
      filterQuery: [],
      filterOptions: {
      },
    };
  },
  mounted() {
    if (this.apiKey) {
      this.$store.commit('root/setApiKey', this.apiKey);
    }
    let page = this.$route.query.page;
    if (page === 'rental') {
      this.getRentals();
    } else {
      this.getLessonWatched();
    }

    document.addEventListener('keydown', this.bindEnter);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.bindEnter);
  },
  created() {
  },
  computed: {
    ...mapState({
      perPage: (state) => state.statistics.perPage,
      currentPage: (state) => state.statistics.currentPage,
      items: (state) => state.statistics.items,
      isBusy: (state) => state.statistics.isBusy,
      totalCount: (state) => state.statistics.totalCount,
    }),
  },
  methods: {
    createSortQuery() {
      let sorting;
      if (this.orderColumn) {
        if (this.orderDirection === true) {
          sorting = encodeURIComponent('+' + this.orderColumn);
        } else {
          sorting = encodeURIComponent('-' + this.orderColumn);
        }
        return '&sort[]=' + sorting;
      }
      return '';
    },
    bindEnter(e) {
      if (e.keyCode === 13) {
        this.applyFilters();
      }
    },
    loadItems() {
      this.$store.dispatch('statistics/' + this.loadItemMethod, {
        params: {
          offset: this.perPage * (this.currentPage - 1),
          limit: this.perPage
        },
        filters: this.filterQuery,
        sort: this.createSortQuery()
      });
    },

    getLessonWatched() {
      this.loadItemMethod = 'getLessonWatched';
      this.type = 'lessonWatched';
      this.fields = [
        {key: 'id', label: this.$t('table.header.statistic.id')},
        {key: 'name', label: this.$t('table.header.statistic.lessonName')},
        {key: 'masterClassName', label: this.$t('table.header.statistic.masterClassName')},
        {key: 'authors', label: this.$t('table.header.statistic.authors')},
        {key: 'watchedAmount', label: this.$t('table.header.statistic.watchedAmount')},
      ];

      this.loadItems();
    },
    getRates() {
      this.loadItemMethod = 'getRates';
      this.type = 'rate';
      this.fields = [
        {key: 'source', label: this.$t('table.header.statistic.source')},
        {key: 'name', label: this.$t('table.header.statistic.name')},
        {key: 'authors', label: this.$t('table.header.statistic.authors')},
        {key: 'averageRate', label: this.$t('table.header.statistic.averageRate')},
      ];

      this.loadItems();
    },
    getRentals() {
      this.loadItemMethod = 'getRental';
      this.type = 'rental';
      this.fields = [
        {key: 'scoreId', label: this.$t('table.header.statistic.id')},
        {key: 'scoreName', label: this.$t('table.header.statistic.scoreName')},
        {key: 'allRentedAmount', label: this.$t('table.header.statistic.allRentedAmount')},
        {key: 'activeRentedAmount', label: this.$t('table.header.statistic.activeRentedAmount')},
      ];

      this.loadItems();
    },
    changePage: function(currentPage) {
      this.$store.commit('statistics/setCurrentPage', currentPage);
    },
    paginationReload(data) {
      this.$store.commit('statistics/setPerPage', data.perPage);
      this.changePage(data.currentPage);
      this.loadItems();
    },
  },
  watch: {
    orderColumn: function () {
      this.loadItems();
    },
    orderDirection: function () {
      this.loadItems();
    },
    filterQuery: function() {

    }
  }
}
</script>
